/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 *
 * Licensed under the terms of the LICENSE file distributed with this project.
 */

import * as IconContents from "./generated/iconContents";
import * as IconNames from "./generated/iconNames";

export { IconContents, IconNames };
export { IconSvgPaths16, IconSvgPaths20 } from "./generated/iconSvgPaths";
export { IconName } from "./iconName";
